import mitt from "mitt";
import TDesign from "tdesign-mobile-vue";
import "tdesign-mobile-vue/es/style/index.css";
import { createApp } from "vue";
import App from "./App.vue";
import "./assets/css/style.css";
import router from "./router";
import store from "./store/tabbar";

import { createPinia } from "pinia";
import Tools from "./utils/tools";
const pinia = createPinia();

const app = createApp(App);
app.use(pinia).use(store).use(router).use(TDesign);
app.config.globalProperties.$decodeContent = Tools.decodeContent;
app.config.globalProperties.$showToast = Tools.showToast;
app.config.globalProperties.$showLoading = Tools.showLoading;
app.config.globalProperties.$bus = mitt();

app.mount("#app");

export const globals = app.config.globalProperties;
