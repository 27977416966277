<template>
  <t-tab-bar
    v-model="selItem"
    :split="false"
    @change="tabbarChanged"
    :safeAreaInsetBottom="true"
  >
    <t-tab-bar-item v-for="item in list" :key="item.value" :value="item.value">
      {{ item.label }}
      <template #icon>
        <span :class="'iconfont icon-nav-' + item.value"></span>
      </template>
    </t-tab-bar-item>
  </t-tab-bar>
</template>

<script setup>
import emitter from "@/utils/mitt.js";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useTabbarStore } from "../store/tabbar";

const router = useRouter();
const tabbarStore = useTabbarStore();

const selItem = ref(tabbarStore.localItem);
const list = ref([
  {
    value: "home",
    label: "首页",
    path: "/home",
  },
  {
    value: "intro",
    label: "云展标准",
    path: "/intro",
  },
  {
    value: "show",
    label: "她风采",
    path: "/show",
  },
  {
    value: "my",
    label: "个人中心",
    path: "/center",
  },
]);

onMounted(() => {
  // selItem.value = window.localStorage.getItem("tabbar_value") ?? "home";
  emitter.on("sel_item", (val) => {
    tabbarStore.updateCurItem(val);
    selItem.value = val;
  });
});

function tabbarChanged(e) {
  tabbarStore.updateCurItem(e);
  router.replace("/" + e);
}
</script>

<style></style>
