import { Base64 } from "js-base64";
import { Toast } from "tdesign-mobile-vue";
import wx from "weixin-js-sdk";
import { ticket } from "../network/API";

function calcMaxPage(total = 0, size = 10) {
  let num = total % size == 0 ? total / 10 : parseInt(total / size) + 1;
  return num > 0 ? num : 1;
}

function decodeContent(content = "") {
  return Base64.decode(content);
}

function formatDuration(time) {
  let hour = time / 60;
  let min = (time - hour * 60) / 60;
  let sencond = time - hour * 60 - min * 60;
  return hour + "h" + min + "′" + sencond + "″";
}

function indexToChinese(idx) {
  if (idx == 0) {
    return "首";
  } else if (idx == 1) {
    return "二";
  } else if (idx == 2) {
    return "三";
  } else if (idx == 3) {
    return "四";
  } else if (idx == 4) {
    return "五";
  }
}

function compareDate(timeA, timeB) {
  let staytimeGap = new Date(timeB).getTime() - new Date(timeA).getTime();
  let stayHour = Math.floor(staytimeGap / (3600 * 1000)); // 小时
  let leave1 = staytimeGap % (3600 * 1000);
  let stayMin = Math.floor(leave1 / (60 * 1000)); // 分钟
  let leave2 = leave1 % (60 * 1000);
  let staySec = Math.floor(leave2 / 1000); // 秒
  return staytimeGap > 0;
}

function cloudPathToLocal(url) {
  const location = url;
  let path = location.split(".com/")[1];
  path = "https://vote-image.hbzhengwu.com/" + path;
  return path;
}

function showToast(msg) {
  Toast(msg);
}
function showLoading() {
  Toast({
    direction: "column",
    preventScrollThrough: true,
    duration: 99999,
    showOverlay: true,
    theme: "loading",
    onClose: () => {},
  });
}

async function shareInit(
  title = "燕赵她志愿",
  imgUrl = "https://vote-image.hbzhengwu.com/uploads/heroine/image/logo.png?123"
) {
  console.log("title ==" + title);

  let res = await ticket({
    url: window.location.origin + window.location.pathname,
  });
  console.log(res);

  let that = this;
  wx.config({
    debug: false,
    nonceStr: res.noncestr,
    appId: "wx7da5e703d24fa74f",
    signature: res.signature,
    timestamp: res.timestamp,
    jsApiList: [
      "updateAppMessageShareData",
      "updateTimelineShareData",
      "onMenuShareAppMessage",
      "onMenuShareTimeline",
    ],
  });
  wx.checkJsApi({
    jsApiList: [
      "updateAppMessageShareData",
      "updateTimelineShareData",
      "onMenuShareAppMessage",
      "onMenuShareTimeline",
    ],
    success: (res) => {},
    fail: (err) => {},
  });
  wx.ready(() => {
    wx.updateAppMessageShareData({
      title: title,
      desc: "",
      link: window.location.origin + window.location.pathname,
      imgUrl: imgUrl,
      success: (res) => {
        // alert("update success");
      },
      fail: (err) => {},
    });
    wx.updateTimelineShareData({
      title: title,
      desc: "",
      link: window.location.origin + window.location.pathname,
      imgUrl: imgUrl,
      success: (res) => {
        // alert("update success");
      },
      fail: (err) => {},
    });
  });
  wx.error((err) => {});
}

export default {
  decodeContent,
  calcMaxPage,
  indexToChinese,
  formatDuration,
  compareDate,
  cloudPathToLocal,
  showToast,
  showLoading,
  shareInit,
};
